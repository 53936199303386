import Vue from 'vue'
import store from '../store'

export default {
  async checkout ({
    id, v,
    tokenUrl = '/booking/cc',
    paymentUrl = '/booking/cc_payment',
    data = {}
  }) {
    const baseApi = Vue.prototype.$baseApi

    const response = await baseApi({
      method: 'POST',
      url: tokenUrl,
      params: {
          id,
          v
      }
    })
    const tokenApi = response.token

    return new Promise((resolve, reject) => {
      const handler = window.StripeCheckout.configure({
        key: 'pk_test_3HBGvpLtVQofx3jvkYusnM45',
        image: store.state.service.company.Logo,
        allowRememberMe: false,
        locale: 'auto',
        billingAddress: true,
        zipCode: true,
        token: async (token) => {
          baseApi({
              method: 'POST',
              url: paymentUrl,
              data: {
                  token: token.id,
                  id: tokenApi,
                  total: data.amount
              }
          })
          .then(resolve)
          .catch(reject)
        }
      })

      const { currency = 'idr', amount } = data

      handler.open({
       ...data,
        currency,
        amount: amount * 100
      })
    })
  }
}
